import { EntrevistaPergunta, EntrevistaResposta } from '../types/entrevista';
import api, { listAll } from './api';

const entrevistaApi =  {
  listarRespostas() {
    return listAll<EntrevistaResposta>({endpoint: 'entrevistas/respostas'});
  },

  listarPerguntas() {
    return listAll<{
      perguntas: EntrevistaPergunta[];
      contagem_resposta: number;
    }>({endpoint: 'entrevistas/perguntas'});
  },

  saveAnswer(data: {
    resposta: Blob | string;
    perguntaId: number;
    contagem_resposta: number;
  }) {
    const formData = new FormData();

    formData.append('resposta', data.resposta);
    formData.append('pergunta_id', data.perguntaId.toString());
    formData.append('contagem_resposta', data.contagem_resposta.toString());

    return api.post(`entrevistas/respostas`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
  }
};

export default entrevistaApi;
