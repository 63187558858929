import { useEffect, useState } from 'react';
import PageContainer from '../../../components/container/PageContainer';
import { EntrevistaPergunta } from '../../../types/entrevista';
import Alert from '../../../helpers/Alert';
import service from '../../../services/entrevistaApi';
import Input from '../../../components/inputs/Input';
import Button from '../../../components/button/Button';
import InputSelect from '../../../components/inputSelect';
import Camera from '../../../components/devices/Camera';
import useWebcam from '../../../hooks/useWebcam';

export default function AnswerTest() {
  const [contagem, setContagem] = useState(0);
  const [respostas, setRespostas] = useState<{ [key: number]: any }>({});
  const [perguntas, setPerguntas] = useState<EntrevistaPergunta[] | null>(null);
  const [index, setIndex] = useState(0);

  const pergunta = perguntas?.length ? perguntas[index] : null;
  const resposta = pergunta ? (respostas[pergunta.id] ?? '') :  '';

  const webcam = useWebcam({
    videoTagId: 'video'
  });


  async function load() {
    Alert.await('Carregando perguntas', 'Aguarde...');
    const { data, isError } = await service.listarPerguntas();
    Alert.close();
    if (isError) {
      Alert.error(data.message || data.error || 'Falha ao carregar perguntas.');
      return;
    }
    setContagem((data as any).contagem_resposta);
    setPerguntas((data as any).perguntas);
  }

  async function next() {
    if (resposta) {
      saveAnswer();
    }

    if (index < (perguntas?.length ?? 0) - 1) {
      setIndex(index + 1);
    } else {
      await Alert.success('Teste finalizado com sucesso!');
      window.location.href = '/entrevista-simulada';
    }
  }

  function prev() {
    if (index > 0) {
      setIndex(index - 1);

    }
  }

  async function saveAnswer() {

    try {
      await service.saveAnswer({
        contagem_resposta: contagem,
        perguntaId: pergunta!.id,
        resposta: pergunta?.tipo === 'Vídeo' ? resposta.blob : resposta
      });
    } catch (err: any) {
      return Alert.error(err.message || err.error || 'Falha ao salvar resposta.');
    }
  }

  async function recordFinished({ blob, url }: { blob: Blob, url: string }) {
    setRespostas({ ...respostas, [pergunta!.id]: { url, blob } });
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <PageContainer>
      <div className={'text-[30px] mb-4'}>Responder Entrevista</div>

      {!!pergunta && (
        <div className='max-w-[640px]'>
          <p className="font-medium">Pergunta {index + 1} de {perguntas?.length}</p>
          <small className='text-gray-500'>Perguntas com (*) são obrigatórias</small>
          <br />
          <br />
          <p className="font-bold">{pergunta.enunciado} {pergunta.obrigatorio ? '*' : ''}</p>

          {pergunta.tipo === 'Texto' &&  <Input 
            placeholder='Resposta'
            value={resposta}
            setValue={(value) => setRespostas({ ...respostas, [pergunta.id]: value.trim() })}
          />}

          {pergunta.tipo === 'Vídeo' && (
            <div>
              <Camera 
                {...webcam} 
                recordFinished={recordFinished} 
                videoUrl={resposta.url}
                maxSeconds={pergunta.tempo_maximo_resposta}
              />
            </div>
              
          )}

          <footer className='flex justify-between'>
            <div>
              {index > 0 && <Button
                onClick={prev}
                className='border border-primary h-[40px] font-medium mt-4'
              >
                Voltar
              </Button>}
            </div>
            <div>
              <Button
                onClick={next}
                disabled={!resposta && pergunta.obrigatorio}
                className='bg-primary h-[40px] text-white font-medium mt-4'
              >
                Avançar
              </Button>
            </div>

          </footer>
        </div>
      )}
    </PageContainer>
  )
}
