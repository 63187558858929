import Webcam from "react-webcam";
import InputSelect from "../../inputSelect";
import Button from "../../button/Button";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

export type CameraProps = {
  microphones: MediaDeviceInfo[];
  cameras: MediaDeviceInfo[];
  selectedMicrophone: any;
  selectedCamera: any;
  setSelectedCamera: any;
  setSelectedMicrophone: any;
  startRecording: any;
  stopRecording: any;
  recordFinished: any;
  videoUrl: any;
  maxSeconds?: number;
}

export default function Camera(props: CameraProps) {
  const [recording, setRecording] = useState(false);
  const [counter, setCounter] = useState(0);

  let [interval, setIntervalValue]: any = useState(null);

  async function handleRecord() {
    if (recording) {
      stopRecording();
    } else {
      setCounter(3);
    }
  }

  async function stopRecording() {
    const data = await props.stopRecording();
      props.recordFinished(data);
      setRecording(false);
      clearInterval(interval!);
  }

  function startTimer(duration: number, display = '#timer') {
    var timer: any = duration, minutes, seconds;
    setIntervalValue(setInterval(function () {
        minutes = parseInt(timer / 60 as any, 10);
        seconds = parseInt(timer % 60 as any, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        document.querySelector(display)!.innerHTML = minutes + ":" + seconds;

        if (minutes + ":" + seconds === "00:00") {
          stopRecording();
        }

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000));
}

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        if (counter === 1) {
          props.startRecording();
          setRecording(true);
          
        }

        if (counter === 2) {
          if (props.maxSeconds) {
            startTimer(props.maxSeconds);
          }
        }
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <div className='max-w-[640px]'>
        <main>
          <div style={{ display: !counter && !recording && props.videoUrl ? 'block' : 'none' }}>
            <ReactPlayer url={props.videoUrl} controls={true} />
          </div>

          <div className='bg-black mt-10 rounded relative' id='canvas' style={{ display: !counter && !recording && props.videoUrl ? 'none' : 'block' }}>
            <Webcam 
              audio={false} 
              width={640} 
              height={416} 
              className="mt-7" 
              videoConstraints={{ deviceId: props.selectedCamera?.deviceId }}
              audioConstraints={{ deviceId: props.selectedMicrophone?.deviceId }}
            />

            {counter > 0 && <div id="video-overlay" className="absolute inset-0 bg-black opacity-60">
              <h2 className="text-white font-bold" style={{ fontSize: '200px', width: '100%', textAlign: 'center', height: '100%', marginTop: '15%' }}>
                { counter }
              </h2>
            </div>}

            <div id="video-overlay" className="absolute inset-0" style={{ display: recording && props.maxSeconds ? 'block' : 'none' }}>
              <h2 className="shadow text-white font-bold bg-primary rounded p-1 px-2 max-w-[60px] mt-2 ml-auto mr-16" id="timer"></h2>
            </div>

            {recording && <div id="video-overlay" className="absolute inset-0">
              <h2 className="shadow text-white font-bold bg-red-500 rounded p-1 px-2 max-w-[50px] mt-2 ml-auto mr-2">
                REC
              </h2>
            </div>}
          </div>
        </main>

        <div className="grid grid-cols-2 gap-4">
          <InputSelect
            label='Microfone' 
            options={props.microphones.map(microphone => ({ label: microphone.label, value: microphone.deviceId }))} 
            value={props.selectedMicrophone?.deviceId}
            setValue={(value: any) => props.setSelectedMicrophone(props.microphones.find(microphone => microphone.deviceId === value))}
          />

          <InputSelect
            label='Câmera' 
            options={props.cameras.map(cameras => ({ label: cameras.label, value: cameras.deviceId }))}
            value={props.selectedCamera?.deviceId}
            setValue={(value: any) => props.setSelectedCamera(props.cameras.find(camera => camera.deviceId === value))}
          />
        </div>

        <footer>
          <Button 
            disabled={counter > 0}
            className={!counter && !recording && props.videoUrl ? 'border border-primary h-[40px] font-medium mt-4 w-full'  : 'bg-primary h-[40px] text-white font-medium mt-4 w-full'} 
            onClick={handleRecord}
          >
            {!counter && !recording && props.videoUrl ? 'Excluir e Gravar Novamente' :  recording ? 'Parar Gravação' : 'Iniciar Gravação' + (
              props.maxSeconds ? ` (Até ${props.maxSeconds / 60} minutos)` : ''
            )}
          </Button>
        </footer>
    </div>
  );
}